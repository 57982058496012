import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useStore } from "../stores/use_global_state";

import Layout from "../components/layout";
import OpenGraphTwitterCard from "../components/seo/OpenGraphTwitterCard";
import getCommonLocales from "../locales/locales_common";
import getErrorLocales from "../locales/locales_error";
import getProfileLocales from "../locales/locales_profile";
import getRemoveBgPageLocales from "../locales/locales_remove_bg";
import NewEntryBlock from "../components/main_page/EntryBlock/NewEntryBlock";
import About from "../components/main_page/About/About";
import UseCases from "../components/main_page/UseCases/UseCases";
import MadeWith from "../components/main_page/MadeWith/MadeWith";
import BottomBanner from "../components/main_page/BottomBanner/BottomBanner";
import FaqComponent from "../components/FaqComponent/FaqComponent";
const DynamicWorkspace = dynamic(() => import("../components/Workspace"));
import { MemoizedReviews } from "../components/main_page/UserReviews/NewUserReviews";
import Script from "next/script";


export default function Home() {
  const { state } = useStore();

  const { locale, pathname } = useRouter();
  const dictionary = { ...getCommonLocales(locale), ...getErrorLocales(locale), ...getProfileLocales(locale), ...getRemoveBgPageLocales(locale) };

  const title = dictionary["page_main_reviews_title"];
  const description = dictionary["page_main_reviews_description"];

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": title,
    "description": description,
    "applicationCategory": "DesignApplication",
    "operatingSystem": "Any",
    "url": `https://bgrem.ai/${locale !== "en" ? locale + "/" : ""}`,
  };


  return (
    <Layout mainClassName="ai-main">
      <Script id="schema-org-main" type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
      <OpenGraphTwitterCard title={title} description={description} url={pathname} canonical={`https://bgrem.ai/${locale !== "en" ? locale + "/" : ""}`} />
      {state.load === false && <div className="load" />}
      {state.isAuth === false && (
        <>
          <NewEntryBlock locale={locale} dictionary={dictionary} />
          <About locale={locale} dictionary={dictionary} />
          <UseCases locale={locale} dictionary={dictionary} />
          <MadeWith locale={locale} dictionary={dictionary} />
          <MemoizedReviews dictionary={dictionary} type={"main"} />
          <FaqComponent dictionary={dictionary} pageName={"main"} />
          <BottomBanner dictionary={dictionary} isMainPage={true} />
        </>
      )}

      {state.isAuth === true && <DynamicWorkspace dictionary={dictionary} />}
    </Layout>
  );
}
