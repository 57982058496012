import style from "./NewEntryBlock.module.scss";
import dynamic from "next/dynamic";
import { useState } from "react";
import { HandySvg } from "handy-svg";
import Link from "next/link";
import Image from "next/image";
import Head from "next/head";
import { features } from "./constants";
const AuthModal = dynamic(() => import("../../AuthModal/AuthModal"), { ssr: false });

function NewEntryBlock({ dictionary, locale }) {
	const [showAuthModal, setShowAuthModal] = useState(false);

	const title_first = dictionary["take_your_images_videos"] || "Take your images & videos to the";
	const title_second = dictionary["next_level_withai"] || "next level with AI";
	const description = dictionary["page_main_description"] || "Incredible AI tools for creating and editing your media content";

	const schemaData = {
		"@context": "https://schema.org",
		"@type": "WebPage",
		"name": `${title_first} ${title_second}`,
		"description": description,
		"mainEntity": {
			"@type": "ItemList",
			"itemListElement": features.map((feature, index) => ({
				"@type": "ListItem",
				"position": index + 1,
				"item": {
					"@type": "SoftwareApplication",
					"name": dictionary[feature.name] || feature.name,
					"description": dictionary[feature.description] || feature.description,
					"applicationCategory": "Multimedia",
					"url": feature.link_url !== "soon" ? `https://bgrem.ai${locale !== "en" ? `/${locale}` : ""}${feature.link_url}` : undefined
				}
			}))
		}
	};

	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
				/>
			</Head>
			<section className={style.container}>
				<div className={style.top}>
					<h1 className={style.title}>
						{title_first}{" "}
						<span>
							{title_second} <HandySvg src="/main_page/entry_block/logo.svg" width="36" height="36" />
						</span>
					</h1>
					<p className={style.subtitle}>{description}</p>
					<button className={style.link} onClick={() => setShowAuthModal(true)}>
						{dictionary["sign_up_get_free_credits"] || "Sign up & Get free credits"}
					</button>
				</div>
				<div className={style.serviceWrapper}>
					{features.map((feature) => (
						<Link key={feature.name} href={feature.link_url !== "soon" ? feature.link_url : "/"} className={`${style.features_item} ${feature.link_url === "soon" ? style.disabled : undefined}`}>
							<div className={style.imageWrapper}>
								<Image className={style.item_img} src={`/main_page/entry_block/service/${feature.description}.png`} alt={feature.name} width={362} height={230} quality={95} priority />
								{feature.result && (
									<>
										<span className={style.original}>{dictionary["Original"]}</span>
										<span className={style.result}>{dictionary["Result"]}</span>
									</>
								)}
								{feature.prompt && (
									<div className={style.prompt}>
										<span>{dictionary["prompt"]}</span> {dictionary[feature.prompt] || feature.prompt}
									</div>
								)}
							</div>
							<div className={style.item_text}>
								<h3 className={style.item_title}>
									{dictionary[feature.name]}{" "}
									<span>
										{dictionary["discover"] || "Discover"} <HandySvg src="/illustration_service/back.svg" width={24} height={24} className={style.item_next} />
									</span>
								</h3>
								<p className={style.item_description}>{dictionary[feature.description]}</p>
							</div>
						</Link>
					))}
				</div>
			</section>
			{showAuthModal && <AuthModal closeHandler={() => setShowAuthModal(false)} serviceName="mainPage" />}
		</>
	);
}

export default NewEntryBlock;